<template>
  <div class="page">
    <!-- 顶部切换 防疫统计or精准防疫 -->
    <div class="main-tab">
      <div
        :class="[
          'main-tab-item',
          { 'main-tab-item-active': index == mainTabActive },
        ]"
        v-for="(item, index) in mainTabList"
        :key="index"
        @click="tabSelect('first', item, index)"
      >
        {{ item }}
        <div class="line"></div>
      </div>
    </div>
    <!-- 防疫统计 -->
    <div class="fangyitongji" v-show="mainTabActive == 0">
      <img src="./img/bg.png" class="bg" />
      <!-- 导航标签切换 核酸or健康码or疫苗 -->
      <div class="modular1">
        <div class="tab">
          <div
            :class="['tab-item', { 'tab-item-active': item.text == tabActive }]"
            v-for="(item, index) in tabList"
            :key="index"
            @click="tabSelect('second', item, index)"
          >
            <span>{{ item.text }}<i v-if="index == tabActive"></i></span>
          </div>
        </div>
        <!-- 核酸 -->
        <div class="nucleicAcid" v-if="tabActive == '核酸'">
          <div class="item" v-for="(item, index) in HSlist" :key="index">
            <div class="name">
              <img src="./img/sum-icon.png" v-if="index == 0" />
              {{ item.name }}
            </div>
            <div
              :class="[
                'num',
                index + 1 == HSlist.length ? 'grey' : classNameListHS[index],
              ]"
            >
              {{ item.value }}
              <span>人</span>
            </div>
          </div>
        </div>
        <!-- 健康码 -->
        <div class="healthCode" v-else-if="tabActive == '健康码'">
          <div
            class="item"
            v-for="(item, index) in JKMlist.slice(1)"
            :key="index"
          >
            <div class="name">{{ item.checkstatus }}</div>
            <div :class="['num', classNameListJKM[index]]">
              {{ item.sums }}
              <span>人</span>
            </div>
          </div>
          <div class="sum" v-if="JKMlist.length > 0">
            <img src="./img/sum-icon.png" />
            总人数
            <div :class="['num', 'black']">
              {{ JKMlist[0].sums }}
              <span>人</span>
            </div>
          </div>
        </div>
        <!-- 疫苗 -->
        <div class="vaccines" v-else>
          <div
            class="item-YM"
            v-for="(item, index) in YMlist.slice(1)"
            :key="index"
          >
            <div class="name">{{ item.name }}</div>
            <div :class="['num', classNameListYM[index]]">
              {{ item.value }}
              <span>人</span>
            </div>
          </div>
          <div class="sum" v-if="YMlist.length > 0">
            <img src="./img/sum-icon.png" />
            总人数
            <div :class="['num', 'black']">
              {{ YMlist[0].value }}
              <span>人</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 防疫统计 - echarts -->
      <div class="echart1">
        <div class="inBox">
          <div class="box-top">
            <div class="title">
              各{{ role == 1 ? "小区" : "楼幢" }}健康检测覆盖率
            </div>
            <div class="typeBox" @click="pickerClick(1)">
              {{ typeStr }}
              <img src="./img/down-arrow.png" />
            </div>
          </div>
          <!-- 条形图 -->
          <div class="chartsBox" ref="charts"></div>
        </div>
      </div>

      <!-- 防疫统计 - 年龄段分布 -->
      <div class="echart2">
        <div class="inBox">
          <div class="box-top">
            <div class="homeName">
              <div class="homeBox" @click="pickerClick(51)">
                <span>{{ houseAge.text }} </span>
                <img src="./img/down-arrow.png" />
              </div>
              <div class="buildBox" v-show="role != 1" @click="pickerClick(61)">
                <span>{{ buildAge.text }}</span>
                <img src="./img/down-arrow.png" />
              </div>
            </div>
            <div class="typeBox" @click="pickerClick(11)">
              {{ typeAgeStr }}
              <img src="./img/down-arrow.png" />
            </div>
          </div>
          <div class="chartsBox" ref="ageCharts"></div>
        </div>
      </div>
      <!-- 底部列表 -->
      <div class="modular3">
        <div class="inBox">
          <div class="top-box">
            <div class="left" @click="pickerClick(2)">
              {{ rankMaps[rankStr] }}
              <img src="./img/down-arrow.png" />
            </div>
            <div class="right" @click="pickerClick(3)">
              {{ timeStr }}
              <img src="./img/down-arrow.png" />
            </div>
          </div>
          <div class="rankBox">
            <div class="top-line" v-if="rankStr == 1">
              <div
                class="header"
                v-for="(item, index) in meshTabs"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="top-line" v-else>
              <div
                class="header"
                v-for="(item, index) in housekeeperTabs"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="bottom-line">
              <div
                class="item-line"
                v-for="(v, i) in rankStr == 1 ? meshList : housekeeperList"
                :key="i"
              >
                <div class="item">{{ v.userName }}</div>
                <div class="item">{{ v.mobileNum }}</div>
                <div class="item">{{ v.allNum }}</div>
                <div class="item">{{ v.checkNum }}</div>
                <div class="item">{{ v.radio }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 精准防疫 -->
    <div class="jingzhunfangyi" v-show="mainTabActive == 1">
      <img src="./img/bg.png" class="bg" />
      <div class="box">
        <div class="inbox">
          <div class="top-box">
            <div class="typeBox" @click="pickerClick(4)">
              {{ typeStr2 }}
              <img src="./img/down-arrow.png" />
            </div>
            <div class="list" v-if="typeStr2 == '核酸'">
              <div
                :class="[
                  'item',
                  classNameListHS2[index],
                  childTypeStr2 == item.code ? 'select' : '',
                ]"
                v-for="(item, index) in HSlist2"
                :key="index"
                @click="changeChildTypeStr2(item.code)"
              >
                {{ item.name }}（{{ item.num }}人）
              </div>
            </div>
            <div class="list" v-else-if="typeStr2 == '健康码'">
              <div
                :class="[
                  'item',
                  classNameListJKM[index],
                  childTypeStr2 == item.code ? 'select' : '',
                ]"
                v-for="(item, index) in JKMlist2"
                :key="index"
                @click="changeChildTypeStr2(item.code)"
              >
                {{ item.name }}（{{ item.num }}人）
              </div>
            </div>
            <div class="list" v-else>
              <div
                :class="[
                  'item',
                  classNameListYM[index],
                  childTypeStr2 == item.code ? 'select' : '',
                ]"
                v-for="(item, index) in YMlist2"
                :key="index"
                @click="changeChildTypeStr2(item.code)"
              >
                {{ item.name }}（{{ item.num }}人）
              </div>
            </div>
          </div>
          <div class="center-box">
            <div class="homeName">
              <div class="homeBox" @click="pickerClick(5)">
                <span>{{ house.text }} </span>
                <img src="./img/down-arrow.png" />
              </div>
              <div class="buildBox" @click="pickerClick(6)">
                <span>{{ build.text }}</span>
                <img src="./img/down-arrow.png" />
              </div>
            </div>
            <div class="all">全部({{ total }})</div>
          </div>
          <div class="bottom-box">
            <div
              :class="[
                'item',
                { blue: item.type == 1 },
                { green: item.type == 2 },
                { yellow: item.type == 3 },
                { red: item.type == 4 },
                { grey: item.type == 5 },
                typeStr2 == '核酸' ? classNameListHS2[item.type] : '',
              ]"
              v-for="(item, index) in listData"
              :key="index"
            >
              <div class="left">
                <template>
                  <img
                    v-if="
                      item.ageGroup != '0' &&
                      item.ageGroup &&
                      item.sex &&
                      item.sex != '0'
                    "
                    :src="
                      require(`${
                        userMap[item.sex][item.ageGroup] ||
                        './img/user_unknown.png'
                      }`)
                    "
                    alt=""
                  />
                  <img v-else src="./img/user_unknown.png" alt="" />
                </template>
                <div class="icon-age" v-if="!!item.ageGroup">
                  <img
                    v-if="item.ageGroup"
                    :src="require(`${ageGroupMap[item.ageGroup]}`)"
                    alt=""
                  />
                  <img v-else src="./img/unknown.png" alt="" />
                </div>
                <div class="icon-sex" v-if="!!item.sex">
                  <img
                    v-if="item.sex"
                    :src="require(`${sexMap[item.sex]}`)"
                    alt=""
                  />
                  <img v-else src="./img/unknown.png" alt="" />
                </div>
              </div>
              <div class="right">
                <div class="name">{{ item.userName }}</div>
                <div class="home">
                  <img src="./img/home-icon.png" alt="" />
                  {{ item.houseName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-picker
      :columns="pickerList"
      :valueShow="show"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  jzFyStatisticalURL,
  getFyStatisticalURL,
  getJzFyCountInfoURL,
  userInfoURL,
  checkRankURL,
  getStatisticTotalURL,
  getBuildingDetailsByPhoneURL,
  getAgeFyStatisticalURL,
} from "./api";

export default {
  name: "accurateAntiepidemic",
  data() {
    return {
      role: 2, // 1 书记 2 网格 3 普通用户
      myChart: null,
      mainTabActive: 0, // 顶部tab切换 0 防疫统计 1 精准防疫
      mainTabList: ["防疫统计", "精准防疫"],
      pickerList: [], // 下拉框选项
      pickerFlag: null, // v-picker 类型
      tabList: [{ text: "核酸" }, { text: "健康码" }, { text: "疫苗" }],
      show: false,
      /* 防疫统计 */
      // 防疫统计-顶部切换
      tabActive: "核酸", //  核酸 健康码 疫苗
      // 防疫统计 - 覆盖率echart
      typeStr: "核酸",
      // 防疫统计 - 年龄段echart
      typeAgeStr: "核酸",
      myAgeChart: null,
      optionAge: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          // left: "5%",
          // right: "7%",
          // bottom: "5%",
          left: "2%",
          right: "7%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["老年", "壮年", "少年", "未知"],
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: true,
            inside: true,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: true,
            inside: true,
          },
        },
        series: [],
      },
      buildAgeList: [], // 楼幢数据
      houseAge: {}, // 小区
      buildAge: {}, // 楼幢
      // 防疫统计 - 底部列表
      rankStr: 1, // 1 网格长组织核酸检测排行榜 2 管家组织核酸检测排行榜
      timeStr: "24小时",
      timeList: [{ text: "24小时" }, { text: "48小时" }, { text: "7天" }],
      meshTabs: ["网格长", "联系方式", "总人数", "检测数", "检测率"],
      housekeeperTabs: ["管家", "联系方式", "总人数", "检测数", "检测率"],
      meshList: [], // 底部列表数据 - 网格长组织核酸检测排行榜
      housekeeperList: [], // 底部列表数据 - 管家组织核酸检测排行榜
      rankList: [
        { text: "网格长组织核酸检测排行榜", value: 1 },
        { text: "管家组织核酸检测排行榜", value: 2 },
      ],
      rankMaps: {
        1: "网格长组织核酸检测排行榜",
        2: "管家组织核酸检测排行榜",
      },
      classNameListHS: ["black", "green", "blue", "yellow", "red", "grey"],
      // classNameListHS2: ["green", "blue", "yellow", "red", "grey"],
      classNameListHS2: [
        "type_24",
        "type_48",
        "type_72",
        "type_72_",
        "type_none",
      ],
      classNameListJKM: ["green", "yellow", "red", "grey"],
      classNameListYM: ["yellow", "blue", "green", "grey"],
      HSlist: [
        // {
        //   name: "总人数",
        //   num: 5045,
        // },
        // {
        //   name: "24小时",
        //   num: 679,
        // },
        // {
        //   name: "48小时",
        //   num: 1143,
        // },
        // {
        //   name: "72小时",
        //   num: 2876,
        // },
        // {
        //   name: "72小时外",
        //   num: 306,
        // },
        // {
        //   name: "无记录",
        //   num: 41,
        // },
      ],
      JKMlist: [
        // {
        //   name: "绿码",
        //   num: "",
        // },
        // {
        //   name: "黄码",
        //   num: "",
        // },
        // {
        //   name: "红码",
        //   num: "",
        // },
        // {
        //   name: "无记录",
        //   num: "",
        // },
      ],
      YMlist: [
        // {
        //   name: "接种第一针",
        //   num: "",
        // },
        // {
        //   name: "接种第二针",
        //   num: "",
        // },
        // {
        //   name: "接种第三针",
        //   num: "",
        // },
        // {
        //   name: "无记录",
        //   num: "",
        // },
      ],
      optionHS: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          // left: "5%",
          // right: "7%",
          // bottom: "5%",
          left: "2%",
          right: "7%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: true,
            inside: true,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: true,
            inside: true,
          },
        },
        series: [],
      },
      // 精准防疫
      typeStr2: "核酸", // 核酸 健康码 疫苗
      childTypeStr2: undefined, // 子类型
      houseList: [], // 房号数据
      buildList: [], // 幢数据
      house: {}, // 小区
      build: {}, // 楼幢
      total: "", // 全部人数
      HSlist2: [
        {
          name: "24小时",
          code: 1,
          num: "",
        },
        {
          name: "48小时",
          code: 2,
          num: "",
        },
        {
          name: "72小时",
          code: 3,
          num: "",
        },
        {
          name: "72小时外",
          code: 4,
          num: "",
        },
        {
          name: "无记录",
          code: 99,
          num: "",
        },
      ],
      JKMlist2: [
        {
          name: "绿码",
          code: 1,
          num: "",
        },
        {
          name: "黄码",
          code: 2,
          num: "",
        },
        {
          name: "红码",
          code: 3,
          num: "",
        },
        {
          name: "无记录",
          code: 99,
          num: "",
        },
      ],
      YMlist2: [
        {
          name: "接种第一针",
          code: 1,
          num: "",
        },
        {
          name: "接种第二针",
          code: 2,
          num: "",
        },
        {
          name: "接种第三针",
          code: 3,
          num: "",
        },
        {
          name: "无记录",
          code: 99,
          num: "",
        },
      ],
      userMap: {
        // 用户头像
        1: {
          1: "./img/men_old.png",
          2: "./img/men_strong.png",
          3: "./img/men_young.png",
        },
        2: {
          1: "./img/women_old.png",
          2: "./img/women_strong.png",
          3: "./img/women_young.png",
        },
      },
      sexMap: {
        // 用户性别
        1: "./img/men.png",
        2: "./img/women.png",
        0: "./img/unknown.png", // 未知
      },
      ageGroupMap: {
        // 用户年龄段
        1: "./img/old.png",
        2: "./img/strong.png",
        3: "./img/young.png",
        0: "./img/unknown.png", // 未知
      },
      listData: [],
      userInfo: {},
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  watch: {
    tabActive: {
      handler(val) {
        this.getStatisticTotal(val);
      },
    },
    typeStr: {
      handler(val) {
        this.getFyStatistical(val);
      },
    },
    rankStr: {
      handler(val) {
        this.getCheckRank(val, this.timeStr);
      },
    },
    timeStr: {
      handler(val) {
        this.getCheckRank(this.rankStr, val);
      },
    },
    typeStr2: {
      handler(val) {
        this.childTypeStr2 = undefined;
        this.getJzFyCountInfo();
        this.getJzFyStatistical();
      },
    },
    childTypeStr2: {
      handler() {
        this.getJzFyStatistical();
      },
    },
    house: {
      handler(val) {
        if (!val) {
          return;
        }
        this.buildList = val.childrenList;
        this.build = this.buildList[0] || {};
      },
      deep: true,
    },
    build: {
      handler() {
        this.childTypeStr2 = undefined;
        this.getJzFyCountInfo();
        this.getJzFyStatistical();
      },
      deep: true,
    },
    typeAgeStr: {
      handler(val) {
        this.getAgeFyStatistical(val);
      },
      deep: true,
    },
    houseAge: {
      handler(val) {
        if (!val) {
          return;
        }
        this.buildAgeList = val.childrenList || [];
        if (this.role != 1) {
          this.buildAge = this.buildAgeList[0] || {};
        } else {
          this.getAgeFyStatistical(this.typeAgeStr);
        }
      },
      deep: true,
    },
    buildAge: {
      handler() {
        this.getAgeFyStatistical(this.typeAgeStr);
      },
      deep: true,
    },
  },
  created() {
    this.role = this.$route.query.role;
    this.initData();
  },
  async mounted() {
    await this.mycharts();
  },
  methods: {
    async initData() {
      await this.getUserInfo();
      // 获取楼幢信息
      await this.getBuildingDetailsByPhone();
      // 防疫统计 - 次级tab切换 核酸or健康码or疫苗
      this.getStatisticTotal(this.tabActive);
      // 防疫统计 - echarts
      this.getFyStatistical(this.typeStr);
      // 防疫统计 - 底部列表
      this.getCheckRank(this.rankStr, this.timeStr);
      // 精准防疫 -
      this.getJzFyCountInfo();
      // 精准防疫 -
      this.getJzFyStatistical();
    },
    // 获取用户信息
    async getUserInfo() {
      let res = await this.$axios.get(userInfoURL, {
        params: {
          userId: this.userId,
        },
      });
      if (res.code === 200 && res.success) {
        this.userInfo = res.data || {};
        // this.userInfo = {
        //   ...res.data,
        //   mobile: 13486673144, //测试书记
        // };
      } else {
        this.$toast({
          message: "服务器错误，请稍后再试！",
        });
      }
    },
    mycharts() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = echarts.init(this.$refs.charts, "macarons");
      this.myChart.setOption(this.optionHS);
      //图表自适应
      // window.addEventListener("resize", function () {
      //   this.myChart.resize(); // myChart 是实例对象
      // });
    },
    myAgecharts() {
      if (this.myAgeChart) {
        this.myAgeChart.dispose();
      }
      this.myAgeChart = echarts.init(this.$refs.ageCharts, "macarons");
      this.myAgeChart.setOption(this.optionAge);
      //图表自适应
      // window.addEventListener("resize", function () {
      //   this.myChart.resize(); // myChart 是实例对象
      // });
    },
    tabSelect(type, item, index) {
      if (type == "first") {
        this.mainTabActive = index;
      } else if (type == "second") {
        this.tabActive = item.text;
      }
    },
    pickerClick(type) {
      this.pickerFlag = type;
      if (type == 1 || type == 4 || type == 11) {
        this.pickerList = this.tabList;
      } else if (type == 2) {
        this.pickerList = this.rankList;
      } else if (type == 3) {
        this.pickerList = this.timeList;
      } else if (type == 5 || type == 51) {
        this.pickerList = this.houseList;
      } else if (type == 6) {
        this.pickerList = this.buildList;
      } else if (type == 61) {
        this.pickerList = this.buildAgeList;
      }
      this.show = true;
    },
    clickOverlay() {
      this.show = false;
    },
    confirm(value) {
      console.log(value);
      if (this.pickerFlag == 1) {
        this.typeStr = value.text;
      } else if (this.pickerFlag == 2) {
        // 底部列表左侧切换
        this.rankStr = value.value;
      } else if (this.pickerFlag == 3) {
        // 底部列表右侧切换
        this.timeStr = value.text;
      } else if (this.pickerFlag == 4) {
        // 精准防疫
        this.typeStr2 = value.text;
      } else if (this.pickerFlag == 5) {
        // 选择社区
        this.house = value;
      } else if (this.pickerFlag == 6) {
        // 选择幢
        this.build = value;
      } else if (this.pickerFlag == 11) {
        this.typeAgeStr = value.text;
      } else if (this.pickerFlag == 51) {
        this.houseAge = value;
      } else if (this.pickerFlag == 61) {
        this.buildAge = value;
      }
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },

    changeChildTypeStr2(val) {
      if (val == this.childTypeStr2) {
        this.childTypeStr2 = undefined;
      } else {
        this.childTypeStr2 = val;
      }
    },

    /* 接口 */
    // 防疫统计 - 顶部
    getStatisticTotal(val) {
      this.$axios
        .get(getStatisticTotalURL, {
          params: {
            fyType: val,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            let data = res.data || [];
            //  核酸 健康码 疫苗
            if (val === "核酸") {
              this.HSlist = data;
            } else if (val === "健康码") {
              this.JKMlist = data;
            } else if (val === "疫苗") {
              this.YMlist = data;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },
    // 防疫统计 - echarts
    getFyStatistical(val) {
      this.$axios
        .get(getFyStatisticalURL, {
          params: {
            fyType: val,
            phone: this.userInfo.mobile,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.dealData(res.data || [], val);
            this.mycharts();
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },
    // 防疫统计 - echarts2
    getAgeFyStatistical(val) {
      this.$axios
        .get(getAgeFyStatisticalURL, {
          params: {
            fyType: val,
            building:
              this.role == 1 ? this.houseAge.value : this.buildAge.value,
            userRole: this.role,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.dealAgeData(res.data || [], val);
            this.myAgecharts();
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },
    // 防疫统计 - 底部
    getCheckRank(role, timeType) {
      if (timeType === "24小时") {
        timeType = 24;
      } else if (timeType === "48小时") {
        timeType = 48;
      } else if (timeType === "7天") {
        timeType = 168;
      }
      this.$axios
        .get(checkRankURL, {
          params: {
            role,
            fyType: "核酸",
            timeType,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            if (role == 1) {
              this.meshList = res.data;
            } else if (role == 2) {
              this.housekeeperList = res.data;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },
    // 获取房号
    async getBuildingDetailsByPhone() {
      let res = await this.$axios.get(getBuildingDetailsByPhoneURL, {
        params: {
          phone: this.userInfo.mobile,
        },
      });
      if (res.code === 200 && res.data) {
        let data = res.data || [];
        data.forEach((v) => {
          v.text = this.role == 1 ? v.name : v.parentName;
          v.value = this.role == 1 ? v.id : v.parentName;
          let children = v[this.role == 1 ? "children" : "childBuildingList"];
          if (children && children.length > 0) {
            children.forEach((c) => {
              c.text = this.role == 1 ? c.name : c.childName;
              c.value = this.role == 1 ? c.id : c.childSpaceId;
            });
          }
          v.children = children || [];
        });
        const list = data.map((v) => {
          let o = {};
          Object.keys(v).forEach((k) => {
            if (k === "children") {
              o.childrenList = v[k];
              return;
            }
            o[k] = v[k];
          });
          return o;
        });
        this.houseList = list;
        this.house = this.houseList[0];
        this.houseAge = this.houseList[0];
        return;
      }
      this.$toast(res.msg || "操作失败，请稍后重试！");
    },
    // 精准防疫-次标题显示人数
    getJzFyCountInfo() {
      this.$axios
        .get(getJzFyCountInfoURL, {
          params: {
            fyType: this.typeStr2,
            // building: this.build[this.role == 1 ? "id" : "childSpaceId"],
            building: this.build.value,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            if (this.typeStr2 === "核酸") {
              this.HSlist2[0].num = res.data.twentyFour;
              this.HSlist2[1].num = res.data.fortyEight;
              this.HSlist2[2].num = res.data.seventyTwo;
              this.HSlist2[3].num = res.data.seventyTwoOut;
              this.HSlist2[4].num = res.data.dontKnowCount;
              this.total = this.HSlist2.reduce(
                (acc, cur) => acc + parseInt(cur.num),
                0
              );
            } else if (this.typeStr2 === "健康码") {
              this.JKMlist2[0].num = res.data.greenCount;
              this.JKMlist2[1].num = res.data.yellowCount;
              this.JKMlist2[2].num = res.data.redCount;
              this.JKMlist2[3].num = res.data.dontKnowCount;
              this.total = this.JKMlist2.reduce(
                (acc, cur) => acc + parseInt(cur.num),
                0
              );
            } else if (this.typeStr2 === "疫苗") {
              this.YMlist2[0].num = res.data.one;
              this.YMlist2[1].num = res.data.two;
              this.YMlist2[2].num = res.data.third;
              this.YMlist2[3].num = res.data.dontKnowCount;
              this.total = this.YMlist2.reduce(
                (acc, cur) => acc + parseInt(cur.num),
                0
              );
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },
    // 精准防疫-列表
    getJzFyStatistical() {
      this.$axios
        .get(jzFyStatisticalURL, {
          params: {
            fyType: this.typeStr2,
            subtyping: this.childTypeStr2,
            // building: this.build[this.role == 1 ? "id" : "childSpaceId"],
            building: this.build.value,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            // this.isApply = res.data;
            let data;
            if (this.typeStr2 === "核酸") {
              //  1:24小时内 2:24-48 3:48-72 4:72外 5:无记录
              let hsStatusMap = {
                // 1: 2,
                // 2: 1,
                // 3: 3,
                // 4: 4,
                // 5: 5,
                1: 0,
                2: 1,
                3: 2,
                4: 3,
                5: 4,
              };
              data = res.data.map((v) => ({
                ...v,
                type: hsStatusMap[v.hsStatus],
              }));
            } else if (this.typeStr2 === "健康码") {
              let jkmColorMap = {
                黄码: 3,
                绿码: 2,
                红码: 4,
                未知: 5,
              };
              data = res.data.map((v) => ({
                ...v,
                type: jkmColorMap[v.jkmColor],
              }));
            } else if (this.typeStr2 === "疫苗") {
              let ymJcMap = {
                0: 5,
                1: 3,
                2: 1,
                3: 2,
              };
              data = res.data.map((v) => ({
                ...v,
                type: ymJcMap[v.ymJc],
              }));
            }
            this.listData = data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((error) => {
          this.$toast(error.msg || "操作失败，请稍后重试！");
        });
    },

    /* 数据处理 */
    dealAgeData(o, type) {
      // this.$set(
      //   this.optionAge.yAxis,
      //   "data",
      //   list.map((v) => v.name)
      // );
      let series = [];
      if (type === "核酸") {
        series = [
          {
            name: "24小时",
            type: "bar",
            barMaxWidth: "25",
            color: "#87C9FA",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.twentyFour),
            data: [
              o.old.twentyFour,
              o.strong.twentyFour,
              o.young.twentyFour,
              o.dontKnow.twentyFour,
            ],
          },
          {
            name: "48小时",
            type: "bar",
            barMaxWidth: "25",
            color: "#CBB3FF",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.fortyEight),
            data: [
              o.old.fortyEight,
              o.strong.fortyEight,
              o.young.fortyEight,
              o.dontKnow.fortyEight,
            ],
          },
          {
            name: "72小时",
            type: "bar",
            barMaxWidth: "25",
            color: "#97D6C5",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.seventyTwo),
            data: [
              o.old.seventyTwo,
              o.strong.seventyTwo,
              o.young.seventyTwo,
              o.dontKnow.seventyTwo,
            ],
          },
          {
            name: "72小时外",
            type: "bar",
            barMaxWidth: "25",
            color: "#FC9F98",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.seventyTwoOut),
            data: [
              o.old.seventyTwoOut,
              o.strong.seventyTwoOut,
              o.young.seventyTwoOut,
              o.dontKnow.seventyTwoOut,
            ],
          },
        ];
      } else if (type === "健康码") {
        series = [
          {
            name: "绿码",
            type: "bar",
            barMaxWidth: "25",
            color: "#1DB791",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.green),
            data: [
              o.old.green,
              o.strong.green,
              o.young.green,
              o.dontKnow.green,
            ],
          },
          {
            name: "黄码",
            type: "bar",
            barMaxWidth: "25",
            color: "#FFD675",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.yellow),
            data: [
              o.old.yellow,
              o.strong.yellow,
              o.young.yellow,
              o.dontKnow.yellow,
            ],
          },
          {
            name: "红码",
            type: "bar",
            barMaxWidth: "25",
            color: "#FF7E75",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.red),
            data: [o.old.red, o.strong.red, o.young.red, o.dontKnow.red],
          },
        ];
      } else if (type === "疫苗") {
        series = [
          {
            name: "已接种",
            type: "bar",
            barMaxWidth: "25",
            color: "#1DB791",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.inoculated),
            data: [
              o.old.inoculated,
              o.strong.inoculated,
              o.young.inoculated,
              o.dontKnow.inoculated,
            ],
          },
          {
            name: "未接种",
            type: "bar",
            barMaxWidth: "25",
            color: "#FF7E75",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            // data: list.map((v) => v.noInoculate),
            data: [
              o.old.noInoculate,
              o.strong.noInoculate,
              o.young.noInoculate,
              o.dontKnow.noInoculate,
            ],
          },
        ];
      }
      series.push({
        name: "未知",
        type: "bar",
        barMaxWidth: "25",
        color: "#DBDBDB",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        // data: list.map((v) => v.dontKnow),
        data: [
          o.old.dontKnow,
          o.strong.dontKnow,
          o.young.dontKnow,
          o.dontKnow.dontKnow,
        ],
      });
      this.$set(this.optionAge, "series", series);
    },
    dealData(list, type) {
      this.$set(
        this.optionHS.yAxis,
        "data",
        list.map((v) => v.name)
      );
      let series = [];
      if (type === "核酸") {
        series = [
          {
            name: "24小时",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#87C9FA",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.twentyFour),
          },
          {
            name: "48小时",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#CBB3FF",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.fortyEight),
          },
          {
            name: "72小时",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#97D6C5",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.seventyTwo),
          },
          {
            name: "72小时外",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#FC9F98",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.seventyTwoOut),
          },
        ];
      } else if (type === "健康码") {
        series = [
          {
            name: "绿码",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#1DB791",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.green),
          },
          {
            name: "黄码",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#FFD675",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.yellow),
          },
          {
            name: "红码",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#FF7E75",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.red),
          },
        ];
      } else if (type === "疫苗") {
        series = [
          {
            name: "已接种",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#1DB791",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.inoculated),
          },
          {
            name: "未接种",
            type: "bar",
            barMaxWidth: "25",
            stack: "total",
            color: "#FF7E75",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: list.map((v) => v.noInoculate),
          },
        ];
      }
      series.push({
        name: "未知",
        type: "bar",
        barMaxWidth: "25",
        stack: "total",
        color: "#DBDBDB",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: list.map((v) => v.dontKnow),
      });
      this.$set(this.optionHS, "series", series);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f9f9f9;
  z-index: -2;
  .jingzhunfangyi {
    position: relative;
    .box {
      height: 100%;
      padding: 20px 28px;
      box-sizing: border-box;
      .inbox {
        width: 100%;
        overflow: auto;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 48px 28px 20px;
        .bottom-box {
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            max-width: 308px;
            width: 308px;
            height: 120px;
            background: #7cc5e6;
            border-radius: 10px;
            margin-bottom: 18px;
            font-size: 24px;
            color: #ffffff;
            line-height: 34px;
            display: flex;
            // justify-content: center;
            align-items: center;
            // padding: 0 0 30px 24px;
            box-sizing: border-box;
            .left {
              // padding-top: 24px;
              width: 80px;
              min-width: 80px;
              height: 80px;
              margin-left: 24px;
              position: relative;
              > img {
                height: 100%;
                width: 100%;
                line-height: 1;
                vertical-align: middle;
                overflow: hidden;
              }
              .icon-age,
              .icon-sex {
                position: absolute;
                bottom: 0;
                width: 22px;
                height: 22px;
                line-height: 22px;
                > img {
                  vertical-align: middle;
                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                }
              }
              .icon-age {
                left: 0;
              }
              .icon-sex {
                right: 0;
              }
            }
            .right {
              flex: 1;
              margin: 0 12px;
              box-sizing: border-box;
              .name {
                margin-bottom: 10px;
              }
              .home {
                display: flex;
                align-items: center;
                img {
                  width: 22.22px;
                  margin-right: 10px;
                }
              }
            }
          }
          .black {
            background: rgba(0, 0, 0, 0.85);
          }
          .blue {
            background: #7cc5e6;
          }
          .green {
            background: #1db791;
          }
          .yellow {
            background: #ffd675;
          }
          .red {
            background: #ff7e75;
          }
          .grey {
            background: #dbdbdb;
          }

          .type_24 {
            background: #87c9fa;
            // background: linear-gradient(180deg, #72cfed, #4ea6e7);
          }
          .type_48 {
            background: #cbb3ff;
            // background: linear-gradient(180deg, #a584ec, #7e52d2);
          }
          .type_72 {
            background: #97d6c5;
            // background: linear-gradient(180deg, #6bd5bc, #47b496);
          }
          .type_72_ {
            background: #fc9f98;
            // background: linear-gradient(180deg, #4cafb2, #2e6f76);
          }
          .type_none {
            background: #dbdbdb;
          }
        }
        .center-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 40px;
          margin-bottom: 30px;
          .homeName {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            .homeBox,
            .buildBox {
              min-width: 166px;
              height: 58px;
              border-radius: 10px;
              border: 2px solid rgba(0, 0, 0, 0.25);
              font-size: 28px;
              color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 12px 0 18px;
              box-sizing: border-box;
              span {
                display: block;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
              }
              img {
                width: 28px;
                height: 16px;
              }
            }
            .buildBox {
              margin-left: 12px;
              min-width: 120px;
            }
          }
          .all {
            font-size: 24px;
            color: #347eff;
          }
        }
        .top-box::-webkit-scrollbar {
          display: none;
        }
        .top-box {
          width: 100%;
          height: 58px;
          display: flex;
          line-height: 58px;
          overflow-x: scroll;

          .list {
            display: flex;
            .item {
              box-sizing: border-box;
              white-space: nowrap;
              height: 58px;
              margin-left: 22px;
              font-size: 28px;
              color: #ffffff;
              background-color: black;
              padding: 0 0 0 24px;
              border-radius: 10px;
              &.select {
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  left: 8px;
                  top: 8px;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #fff;
                }
                // border: 4px solid rgb(172, 184, 197);
                // box-shadow: 4px 4px 0 4px rgba(0, 0, 0, 0.8);
              }
            }
            .black {
              background: rgba(0, 0, 0, 0.85);
            }
            .green {
              background: #1db791;
            }
            .blue {
              background: #7cc5e6;
            }
            .yellow {
              background: #ffd675;
            }
            .red {
              background: #ff7e75;
            }
            .grey {
              background: #dbdbdb;
            }
            .type_24 {
              background: #87c9fa;
              // background: linear-gradient(180deg, #72cfed, #4ea6e7);
            }
            .type_48 {
              background: #cbb3ff;
              // background: linear-gradient(180deg, #a584ec, #7e52d2);
            }
            .type_72 {
              background: #97d6c5;
              // background: linear-gradient(180deg, #6bd5bc, #47b496);
            }
            .type_72_ {
              background: #fc9f98;
              // background: linear-gradient(180deg, #4cafb2, #2e6f76);
            }
            .type_none {
              background: #dbdbdb;
            }
          }
          .typeBox {
            min-width: 166px;
            height: 58px;
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 0 18px;
            box-sizing: border-box;
            img {
              width: 28px;
              height: 16px;
            }
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: 686px;
      position: absolute;
      z-index: -1;
    }
  }
  .fangyitongji {
    position: relative;
    .modular3 {
      width: 100%;
      padding: 18px 28px;
      box-sizing: border-box;
      // background: #f9f9f9;
      .inBox {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        padding: 26px 34px 1px;
        box-sizing: border-box;
        .rankBox {
          margin-top: 32px;
          .top-line {
            width: 100%;
            height: 60px;
            background: #f2f2f2;
            border-radius: 8px;
            font-size: 24px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            box-sizing: border-box;
            margin-bottom: 20px;
            .header {
              text-align: center;
              flex: 1;
            }
            .header:nth-last-child(1) {
              color: #347eff;
            }
            .header:nth-child(2) {
              flex: 2;
            }
          }
          .bottom-line {
            width: 100%;
            max-height: 1000px;
            overflow: auto;
          }
          .item-line {
            box-sizing: border-box;
            width: 100%;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            margin-bottom: 32px;
            .item {
              flex: 1;
              white-space: normal;
              word-break: break-all;
              word-wrap: break-word;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .item:nth-last-child(1) {
              color: #347eff;
            }
            .item:nth-child(2) {
              flex: 2;
            }
          }
        }
        .top-box {
          width: 100%;
          height: 58px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 58px;
          .left {
            width: 428px;
            height: 58px;
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 0 18px;
            box-sizing: border-box;
            img {
              width: 28px;
              height: 16px;
            }
          }
          .right {
            width: 166px;
            height: 58px;
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 0 18px;
            box-sizing: border-box;
            img {
              width: 28px;
              height: 16px;
            }
          }
        }
      }
    }
    .echart1,
    .echart2 {
      width: 100%;
      padding: 18px 28px;
      box-sizing: border-box;
      // background: #f9f9f9;
      .inBox {
        width: 100%;
        // min-height: 834px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        padding: 30px 32px 30px 36px;
        box-sizing: border-box;
        position: relative;
        .chartsBox {
          width: 100%;
          height: 60vh;
          // position: absolute;
          // top: 88px;
          // left: 50%;
          // transform: translateX(-50%);
          // height: calc(100% - 88px);
          // width: 100%;
        }
        .box-top {
          display: flex;
          height: 58px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .homeName {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            .homeBox,
            .buildBox {
              min-width: 150px;
              height: 58px;
              border-radius: 10px;
              border: 2px solid rgba(0, 0, 0, 0.25);
              font-size: 28px;
              color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 12px 0 18px;
              box-sizing: border-box;
              span {
                display: block;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
              }
              img {
                width: 28px;
                height: 16px;
              }
            }
            .buildBox {
              margin-left: 12px;
              min-width: 100px;
            }
          }
          .title {
            flex: 1;
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
          }
          .typeBox {
            width: 166px;
            height: 58px;
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 0 18px;
            box-sizing: border-box;
            img {
              width: 28px;
              height: 16px;
            }
          }
        }
      }
    }
    .modular1 {
      width: 100%;
      padding: 18px 28px;
      box-sizing: border-box;
      .healthCode,
      .vaccines {
        display: flex;
        margin-top: 18px;
        flex-wrap: wrap;
        justify-content: space-between;
        .item-YM {
          width: 336px;
          line-height: 98px;
          height: 98px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px;
          margin-bottom: 18px;
          display: flex;
          justify-content: space-between;
          padding: 0 48px 0 46px;
          box-sizing: border-box;
          .name {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.44);
            white-space: nowrap;
          }
          .num {
            font-size: 32px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
            span {
              font-size: 24px;
            }
          }
          .black {
            color: rgba(0, 0, 0, 0.85);
          }
          .green {
            color: #1db791;
          }
          .blue {
            color: #7cc5e6;
          }
          .yellow {
            color: #ffd675;
          }
          .red {
            color: #ff7e75;
          }
          .grey {
            color: #dbdbdb;
          }
        }
        .item {
          width: 336px;
          line-height: 98px;
          height: 98px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px;
          margin-bottom: 18px;
          display: flex;
          justify-content: space-between;
          // padding: 0 80px 0 82px;
          padding: 0 48px 0 46px;
          box-sizing: border-box;
          .name {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.44);
            white-space: nowrap;
            margin-right: 8px;
          }
          .num {
            font-size: 32px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
            span {
              font-size: 24px;
            }
          }
          .black {
            color: rgba(0, 0, 0, 0.85);
          }
          .green {
            color: #1db791;
          }
          .blue {
            color: #7cc5e6;
          }
          .yellow {
            color: #ffd675;
          }
          .red {
            color: #ff7e75;
          }
          .grey {
            color: #dbdbdb;
          }
        }
        .sum {
          width: 100%;
          height: 98px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.44);
          img {
            width: 18px;
            height: 20px;
            margin-right: 6px;
          }
          .num {
            font-size: 32px;
            font-weight: 500;
            margin-left: 32px;
            span {
              font-size: 24px;
            }
          }
        }
      }
      .nucleicAcid {
        // display: flex;
        margin-top: 18px;
        // flex-wrap: wrap;
        // justify-content: space-between;
        .item {
          display: inline-block;
          // width: 220px;
          width: 32%;
          height: 156px;
          &:not(:nth-child(3n + 1)) {
            // margin-left: 17px;
            margin-left: 2%;
          }
          &:nth-child(n + 4) {
            margin-top: 18px;
          }
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px;
          box-sizing: border-box;
          padding: 28px 0 28px 0;
          .black {
            color: rgba(0, 0, 0, 0.85);
          }
          .green {
            color: #1db791;
          }
          .blue {
            color: #7cc5e6;
          }
          .yellow {
            color: #ffd675;
          }
          .red {
            color: #ff7e75;
          }
          .grey {
            color: #dbdbdb;
          }
          .num {
            font-size: 32px;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
            margin-top: 22px;
            span {
              font-size: 24px;
            }
          }
          .name {
            display: flex;
            align-items: center;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.44);
            justify-content: center;

            img {
              width: 18px;
              height: 20px;
              margin-right: 6px;
            }
          }
        }
        .item:nth-child(4) {
          margin-bottom: 0;
        }
        .item:nth-child(5) {
          margin-bottom: 0;
        }
        .item:nth-child(6) {
          margin-bottom: 0;
        }
      }
      .tab {
        height: 82px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        display: flex;
        line-height: 82px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        .tab-item {
          flex: 1;
          text-align: center;
        }
        .tab-item-active {
          font-size: 28px;
          font-weight: 600;
          color: #347eff;
          span {
            position: relative;
            i {
              position: absolute;
              top: -2px;
              right: -10px;
              width: 8px;
              height: 8px;
              background: #ffd675;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: 686px;
      position: absolute;
      z-index: -1;
    }
  }

  .main-tab {
    width: 100%;
    height: 120px;
    background-color: #fff;
    display: flex;
    .main-tab-item {
      flex: 1;
      height: 100%;
      line-height: 120px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
    }
    .main-tab-item-active {
      color: #347eff;
      position: relative;
      .line {
        width: 34px;
        height: 6px;
        background: #347eff;
        border-radius: 3px;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .black {
    color: rgba(0, 0, 0, 0.85);
  }
  .green {
    color: #1db791;
  }
  .blue {
    color: #7cc5e6;
  }
  .yellow {
    color: #ffd675;
  }
  .red {
    color: #ff7e75;
  }
  .grey {
    color: #dbdbdb;
  }
}
</style>
