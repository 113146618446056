// 防疫统计信息:核酸/健康码/疫苗 此接口分书记和普通网格员
const getFyStatisticalURL = `/gateway/hc-serve/miniapi/epidemicPrevention/fyStatistical`;
// 获取小区以及楼幢详情
const getBuildingDetailsByPhoneURL = `/gateway/hc-serve/miniapi/epidemicPrevention/getBuildingDetailsByPhone`;
// 精准防疫信息:核酸/健康码/疫苗
const jzFyStatisticalURL = `/gateway/hc-serve/miniapi/epidemicPrevention/jzFyStatistical`;
// 精准防疫统计信息:核酸/健康码/疫苗
const getJzFyCountInfoURL = `/gateway/hc-serve/miniapi/epidemicPrevention/jzFyCountInfo`;

// 宁波接口：顶部内容 核酸/健康码/疫苗
const getStatisticTotalURL = `/gateway/hc-cockpit/view/govern/epidemic/statisticTotal`;
// 宁波接口：底部列表
const checkRankURL = `/gateway/hc-cockpit/view/govern/epidemic/checkRank`;

//个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;

// 防疫统计信息老少统计
const getAgeFyStatisticalURL = `/gateway/hc-serve/miniapi/epidemicPrevention/ageFyStatistical`;

export {
  getFyStatisticalURL,
  getBuildingDetailsByPhoneURL,
  jzFyStatisticalURL,
  getJzFyCountInfoURL,
  checkRankURL,
  getStatisticTotalURL,
  userInfoURL,
  getAgeFyStatisticalURL,
};
